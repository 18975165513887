var mobileBreakpoint = 1200;
var displayWidth = window.innerWidth;
var displayName;
var lottieSrc = '/ResourcePackages/FranceFoodService/assets/img/lottie/';

if (window.location.host === "localhost:3000" ||
    window.location.host === "ramo.daviesmeyer.com") {
    lottieSrc = "./img/lottie/";
}

if (displayWidth >= mobileBreakpoint) {
    displayName = 'desktop';
} else {
    displayName = 'mobile';
}

// Inserts helper menu for html pages
function toggleHelperMenu() {
    const menu = document.querySelector('.helper-menu')
    document.addEventListener('click', function (e) {
        if (e.target && e.target.id == 'helper-menu-btn') {
            menu.classList.toggle('open')
        }
    });
}


function helperMenu() {
    const pages = [
        "menu-generator",
        "profile",
        "photo-library",
        "recipe-listing",
        "profile-saved-menu",
        "profile-saved-recipes",
        "brand-listing",
        "brand-detail",
        'more-content',
        "news-listing",
        "news-detail",
        "product-listing",
        "product-detail",
        "recipe-detail",
        "search-results",
        "contact",
        "coming-soon",
        "menu-generator-intro",
        "simple-text",
        "login",
        "forgot-password-form",
        "index"
    ]

    const menu = document.createElement('div')
    const btn = `<button id="helper-menu-btn" class="helper-menu-btn">M</button>`
    const ul = document.createElement("ul")
    var rootURL = '/';
    if (window.location.host === 'ramo.daviesmeyer.com') {
        rootURL = '/pepsi_fsf/';
    }
    menu.classList.add('helper-menu')
    menu.innerHTML = btn
    menu.appendChild(ul)
    const lis = pages.map(page => {
        const li = `<li><a href="${rootURL}${page}.html">${page}</a></li>`
        return li
    }).join('')

    ul.innerHTML = lis
    document.body.appendChild(menu);
    toggleHelperMenu()
}







// Slick Slider - Carousel counter
function carouselCounter(sliderEl) {
    $(sliderEl).on('init', function (event, slick) {
        const slideCount = slick.slideCount;
        $(this).after('<div class="pfs-container slider-count-sec"><div class="slider-count"><span class="current-num">1</span><span class="spacer"></span><span class="total">' + slideCount + '</span></div></div>');
    });
    $(sliderEl).on('afterChange', function (event, slick) {
        const currentSlide = slick.currentSlide + 1;
        $(this).next('.slider-count-sec').find('.slider-count .current-num').text(currentSlide);
    });
}

function productDetailSlider() {
    var slides_to_show_in_navigation = 4;
    $('.slider-main').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {

        // 
        if (slick.slideCount > slides_to_show_in_navigation) {
            $('.js-product-detail-slider-next-arrow').click(function () {
                $('.slider-main').slick('slickNext');
            });
        } else {
            $('.js-product-detail-slider-next-arrow').remove()
        }
    });

    var sliderMainOptions = {
        arrows: false,
        infinite: true,
        asNavFor: '.slider-nav',
        vertical: true,
        verticalSwiping: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    verticalSwiping: false,
                    vertical: false,
                }
            },

        ]
    }
    var sliderNavOptions = {
        slidesToShow: slides_to_show_in_navigation,
        arrows: false,
        infinite: true,
        asNavFor: '.slider-main',
        vertical: true,
        focusOnSelect: true,
        verticalSwiping: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    // verticalSwiping: false,
                    // vertical: false,
                }
            },
        ]
    }

    $('.slider-main').slick(sliderMainOptions);
    $('.slider-nav').slick(sliderNavOptions);






    $(window).on('orientationchange', function () {
        slickSliderWithResize()

    });

    function slickSliderWithResize() {
        console.log("From slickSliderWithResize");
        $('.slider-main').slick('unslick');
        $('.slider-main').slick(sliderMainOptions);
    }

}
function pfsAccordion() {
    $('.collapse-header').click(function () {
        $('.collapse-item').slideUp();

        if ($(this).hasClass('active')) {
            $(this).next().slideUp();
            $(this).removeClass('active');
        }
        else {
            $(this).next().slideDown();
            $('.collapse-header').removeClass('active');
            $(this).addClass('active');
        }
    });
}
// function deleteSavedRecipePopup() {
//     function closeAll() {
//         $(".card-dropdown").slideUp(100)
//         $(".card-dropdown").removeClass('active')
//     }
//     $('.js-card-top-bar-btn').on('click', function (e) {

//         const btn = e.target;
//         const dropDown = $(btn).next(".card-dropdown")

//         if (!$(dropDown).hasClass('active')) {

//             closeAll()

//             $(this).next().slideDown(100);
//             $(btn).next(".card-dropdown").addClass('active')

//         } else {
//             $(this).next(".card-dropdown").slideUp(100);
//             $(".card-dropdown").removeClass('active')
//         }
//     })

//     $(document).mouseup(function (e) {
//         var container = $('.js-card-top-bar-btn');

//         // if the target of the click isn't the container nor a descendant of the container
//         if (!container.is(e.target) && container.has(e.target).length === 0) {
//             closeAll()
//         }


//     });
// }



$(document).ready(function () {

    // helperMenu()

    // deleteSavedRecipePopup()





    $('.nav-burger').on('click', function () {
        $('.mobile-menu').toggleClass('open');
        $(this).toggleClass('open');
    });

    $('.nav-search img').on('click', function () {
        if (!$('.header-component').hasClass('search-active')) {

            $('.header-component').addClass('search-active')
            $('#js-search-form input').focus()
        }
    });
    $('.close-search').on('click', function () {
        $('#js-search-form input').blur()
        $('.header-component').removeClass('search-active');
    })

    $('ul.mobile-nav li').on('click', function () {
        var _self = $(this);
        if ($(this).find('.submenu').length > 0) {
            _self.toggleClass('active');
        }
    });

    $('footer').on('click', function () {
        $(this).toggleClass('active')
    });

    // -------------------- HOME PAGE SLIDER START --------------------------


    $('#Slider_FG').on('init', function (event, slick) {
        $(slick.$slides.get(0)).find('img').addClass('show')

        bodymovin.loadAnimation({
            container: document.querySelector('.webline_blue'),
            render: 'svg',
            loop: false,
            autoplay: true,
            path: lottieSrc + displayName + "_blue.json"
        });
    });

    $('#Slider_BG').on('init', function (event, slick) {
        var initial_bg_color = window.getComputedStyle($(this).find('.slick-active')[0]).backgroundColor;
        $('body').css({ 'background-color': initial_bg_color })
    });

    $('#Slider_BG').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 700,
        fade: true,
        arrows: false
    });

    $('#Slider_FG').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 700,
        fade: true,
        autoplay: false,
        dots: true,
        arrows: false
    });

    var prevent_scroll = false;

    window.addEventListener('wheel', function (event) {
        if (prevent_scroll) {
            return;
        }

        var scroll_direction = event.deltaY < 0 ? 'up' : 'down'
        prevent_scroll = true;

        setTimeout(function () {
            prevent_scroll = false;
        }, 300)

        if (scroll_direction === 'up') {
            $('#Slider_FG').slick('slickNext')
        } else {
            $('#Slider_FG').slick('slickPrev');
        }
    });

    $('#Slider_BG').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        var new_bg_color = window.getComputedStyle($(this).find('.slick-active')[0]).backgroundColor;
        $('body').css({ 'background-color': new_bg_color })
    })

    $('#Slider_FG').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('#Slider_BG').slick('slickGoTo', nextSlide);

        $('#CurrentSlideNumber').fadeOut(200);
        setTimeout(function () {
            $('#CurrentSlideNumber').html(nextSlide + 1)
        }, 200)
        $('#CurrentSlideNumber').fadeIn(200);
    });


    $('#Slider_FG').on('afterChange', function (event, slick, currentSlide) {
        var item = $(slick.$slides.get(currentSlide)).find('img')
        if (!item.hasClass('show')) {
            item.addClass('show');
        }

        // 0 - blue
        // 1 - green
        // 2 - yellow
        // 3 - red 
        var webline_item;
        switch (currentSlide) {
            case 0:
                webline_item = document.querySelector('.webline_blue');
                if (webline_item.childNodes.length === 0) {
                    bodymovin.loadAnimation({
                        container: webline_item,
                        render: 'svg',
                        loop: false,
                        autoplay: true,
                        path: lottieSrc + displayName + "_blue.json"
                    });
                }
                break;
            // case 1:
            //     webline_item = document.querySelector('.webline_green');
            //     if (webline_item.childNodes.length === 0) {
            //         bodymovin.loadAnimation({
            //             container: document.querySelector('.webline_green'),
            //             render: 'svg',
            //             loop: false,
            //             autoplay: true,
            //             path: lottieSrc + displayName + "_green.json"
            //         })
            //     }
            //     break;
            case 1:
                webline_item = document.querySelector('.webline_yellow');
                if (webline_item.childNodes.length === 0) {
                    bodymovin.loadAnimation({
                        container: document.querySelector('.webline_yellow'),
                        render: 'svg',
                        loop: false,
                        autoplay: true,
                        path: lottieSrc + displayName + "_yellow.json"
                    })
                }
                break;
            case 2:
                webline_item = document.querySelector('.webline_red');
                if (webline_item.childNodes.length === 0) {
                    bodymovin.loadAnimation({
                        container: document.querySelector('.webline_red'),
                        render: 'svg',
                        loop: false,
                        autoplay: true,
                        path: lottieSrc + displayName + "_red.json"
                    })
                }
                break;
            default:
                break;
        }
    });

    // ******************* HOME PAGE SLIDER END ****************

    // ******************* RECIPE LISTING START *********************
    $('#featuredSlider').on('init', function (event, slick, currentSlide, nextSlide) {
        if (slick.slideCount > 2) {
            $('.fs-paginator').find('.fsp-curent').html(1)
            $('.fs-paginator').find('.fsp-total').html(slick.$slides.length)
        } else {
            $('.fs-paginator').remove()
        }
    });
    // $('#featuredSlider').on('init', function (event, slick) {
    //     $('.fs-paginator').find('.fsp-curent').html(1)
    //     $('.fs-paginator').find('.fsp-total').html(slick.$slides.length)
    // });

    $('.filter-button').on('click', function () {
        $('.filter-selects').toggleClass('active');
    });

    $('#featuredSlider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        // speed: 700,      
        autoplay: false,
        arrows: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    centerMode: true
                }
            }
        ]
    });

    $('#featuredSlider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        console.log(slick.$slides.length);
        $('.fs-paginator').find('.fsp-curent').fadeOut(200);
        setTimeout(function () {
            $('.fs-paginator').find('.fsp-curent').html(nextSlide + 1)
        }, 200)
        $('.fs-paginator').find('.fsp-curent').fadeIn(200);
    });

    $('#productSlider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        centerMode: false,
        infinite: true,
        responsive: [
            {
                breakpoint: 1120,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    arrows: false
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    arrows: false
                }
            }
        ]
    });

    $('#iconTilesSlider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        infinite: false,
        // centerMode: true,
        responsive: [
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    variableWidth: true,
                    centerMode: true,
                    infinite: true
                }
            }, {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                    centerMode: true,
                    infinite: true
                }
            }
        ]
    });

    // ******************* RECIPE LISTING END *********************

    // ******************* RECIPE DETAIL START *********************
    $('.recipe-detail-tabs-nav li button').click(function (e) {
        if ($(this).hasClass('is-active')) {
            return;
        }

        $('.recipe-detail-tabs-nav ul li').each(function (key, value) {
            $(value).find('button').toggleClass('is-active');
        });

        $('.recipe-detail-tab').each(function (key, value) {
            $(value).toggleClass('is-active');
        });
    })

    // Slider Counter
    carouselCounter('#Image_Slider')

    $('#Image_Slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 700,
        centerMode: true,
        arrows: true,
        centerPadding: '25%',
        appendArrows: $('#Image_Slider_Arrows'),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerPadding: '10%',
                }
            },
        ]
    });
    // ******************* RECIPE DETAIL END *********************

    // ******************* PRODUCT DETAIL START *********************

    if ($('.slider-nav').length > 0) {
        if ($('.slider-nav').find('img').length > 1) {
            $('.slider-nav').parent().show();
            productDetailSlider();
        }
    }


    pfsAccordion()
    // ******************* PRODUCT DETAIL END *********************

    if ($('#PFS_loginForm').length > 0) {
        const signUpButton = document.getElementById('signUp');
        const signInButton = document.getElementById('signIn');
        const container = document.getElementById('container');

        signUpButton.addEventListener('click', () => {
            container.classList.add("right-panel-active");
        });

        signInButton.addEventListener('click', () => {
            container.classList.remove("right-panel-active");
        });

        $(document).on('click', '.swap-to-log-in', function () {
            console.log('swap to log in clicked')
            $('.pfs-login .sign-up-container').hide()
            $('.pfs-login .sign-in-container').show()
        })

        $(document).on('click', '.swap-to-sign-up', function () {
            console.log('swap to sign up clicked')
            $('.pfs-login .sign-up-container').show();
            $('.pfs-login .sign-in-container').hide();
        })
    }
});

$(window).on('resize', function () {

    displayWidth = document.innerWidth;

    if (window.innerWidth >= mobileBreakpoint) {
        displayName = 'desktop';
        if ($('.mobile-menu').hasClass('open')) {
            $('.mobile-menu').removeClass('open')
        }
    } else {
        displayName = 'mobile';
    }

    if ($('#PFS_loginForm').length > 0) {
        if (window.innerWidth < 992) {
            if ($('#container').hasClass('right-panel-active')) {
                $('#container').removeClass('right-panel-active');
            }
        } else {
            $('.sign-in-container').show();
        }
    }
});